<template>
  <div class="main-box" style="min-height: 800px; background-color: #f5f5f5">
    <el-form class="form-inline" :inline="true" v-if="storeList && storeList.length>0">
      <el-form-item label="选择门店">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
<!--        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>-->
      </el-form-item>
    </el-form>
    <div class="mode-box">
      <div class="item" @click="goLink(1,'创建每日值班表')">
        <div class="itxt">日</div>
        <div class="name">每日值班表</div>
      </div>
      <div class="item" @click="goLink(2,'创建每周值班表')">
        <div class="itxt">周</div>
        <div class="name">每周值班表</div>
      </div>
      <div class="item" @click="goLink(3,'创建每月值班表')">
        <div class="itxt">月</div>
        <div class="name">每月值班表</div>
      </div>
      <div class="item" @click="goLink(5)">
        <div class="itxt">历</div>
        <div class="name">历史记录</div>
      </div>
    </div>
    <el-dialog
        title="查看记录"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <storefour-list :cType="cType" :shopId="search.store" @callFunc="createOk()"></storefour-list>
    </el-dialog>
    <el-dialog
        :title="createTitle"
        :visible.sync="dialogPayVisible"
        :destroy-on-close="true"
        width="30%">
      <create-store-four :weekInfo="weekInfo" @callFunc="createOk()"></create-store-four>
    </el-dialog>
  </div>
</template>
<script>

import storefourList from "@/components/storeFour/storefourList";
import CreateStoreFour from "@/components/CreateStoreFour";
export default {
  components: {
    CreateStoreFour,
    storefourList
  },
  data() {
    return {
      info: false,
      dialogVisible: false,
      weekInfo:  {},
      createTitle: '',
      dialogPayVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        store: ''
      },
      storeList: [],
      isSub: false,
      cType: 0
    };
  },
  created() {
    this.getMyInfo()
  },
  mounted() {
  },
  computed: {},
  methods: {
    addGroup() {
      this.dialogPayVisible = true
    },
    createOk() {
      this.dialogVisible = false
      this.weekInfo = {}
      this.$api.operate.storefourCreate({type:this.cType,shop_id:this.search.store}, res => {
        if(res.errcode==0) {
          // this.$router.push({name: 'operate_storefour_show', query: {id: res.data}})
          // this.createTitle = createTitle
          this.dialogPayVisible = true
          this.weekInfo = res.data
        } else {
          this.isSub = false
          this.fail(res.errmsg)
        }
      })
    },
    goLink(id,createTitle) {
      this.cType = id
      this.createTitle = createTitle
      // if(this.isSub) return false
      // this.isSub = true
      if(id==5) {
        this.$router.push({name: 'operate_storefour', query: {}})
      } else {
        if(this.storeList.length>0 && this.search.store=='') {
          this.fail('请选择门店')
          return false
        }
        this.dialogVisible = true;
      }
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
